<template>
  <div class="powerForm">
    <div class="title">设置角色权限</div>
    <el-form ref="ruleForm" :model="ruleForm" label-width="140px" class="demo-ruleForm">
      <el-form-item label="角色名称：">
        <el-input v-model="ruleForm.roleName" disabled placeholder="请输入角色名称"></el-input>
      </el-form-item>
      <el-form-item label="角色描述：">
        <el-input v-model="ruleForm.description" disabled autosize type="textarea" placeholder="请输入描述信息"></el-input>
      </el-form-item>
      <el-form-item label="权限清单：" class="powerList">
        <el-tree ref="tree" v-loading="loading" default-expand-all node-key="nodeId" :props="defaultProps" :data="data" show-checkbox></el-tree>
      </el-form-item>
      <div class="footerBtn">
        <el-button icon="el-icon-success" :loading="btnLoading" type="primary" @click="ok">保存</el-button>
        <el-button icon="el-icon-refresh-left" @click="cancel">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getRoleNodeTreeByRoleId, setRoleNode } from '@/api/user'
export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      ruleForm: {},
      data: [],
      defaultProps: {
        children: 'child',
        label: 'nodeName',
      },
      currentNodeKeys: [],
      loading: false,
      btnLoading: false,
    }
  },
  mounted() {
    this.ruleForm = { ...this.row }
    this.getTreeData()
  },
  methods: {
    getTreeData() {
      this.loading = true
      getRoleNodeTreeByRoleId({
        data: this.row.roleId,
      })
        .then((res) => {
          this.loading = false
          const data = res.data.roleNodeTree
          this.filterData(data)
          this.data = data
          console.log(this.currentNodeKeys)
          this.$refs.tree.setCheckedKeys(this.currentNodeKeys)
        })
        .catch(() => {
          this.loading = false
        })
    },
    filterData(arr) {
      // if (arr[0].isSelect == 1 && arr[0].parentId != 0 && arr[0].child.length < 2)
      arr.forEach((e) => {
        if (e.isSelect === 1 && e.parentId !== 0 && e.child.length < 2) this.currentNodeKeys.push(e.nodeId)
        if (e.isSelect === 1 && e.parentId === 0 && !e.child.length) this.currentNodeKeys.push(e.nodeId)
        if (e.child && e.child.length > 0) this.filterData(e.child)
      })
    },
    ok() {
      const nodes = [...this.$refs.tree.getHalfCheckedNodes(), ...this.$refs.tree.getCheckedNodes()]
      const roleNodes = []
      nodes.forEach((e) => {
        roleNodes.push({
          nodeId: e.nodeId,
          dataPermission: e.dataPermission,
          isNeedDataPermission: e.isNeedDataPermission,
        })
      })
      const data = {
        roleId: this.row.roleId,
        roleNodes,
      }
      this.btnLoading = true
      setRoleNode({ data })
        .then(() => {
          this.btnLoading = false
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          this.$emit('refresh')
          this.$emit('cancel')
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="less" scoped>
.powerForm {
  border: 1px solid #ddd;
  background-color: #fff;
  .title {
    background-color: #fff;
    padding: 16px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    color: #333;
  }
  ::v-deep .el-form {
    padding: 20px;
    .powerList {
      .el-form-item__content {
        border: 1px solid #eee;
        border-radius: 10px;
        padding: 16px;
      }
      .el-tree {
        height: 240px;
        overflow-y: scroll;
      }
      .el-tree::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      .el-tree::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 50px;
      }
    }
  }
  .footerBtn {
    text-align: center;
  }
}
</style>

import request from '@/utils/request'

export function getPostPage(data) {
  return request({
    url: '/user-web-api/admin/post/getPostPage',
    method: 'POST',
    data,
  })
}
export function getRolePage(data) {
  return request({
    url: '/user-web-api/supplier/role/getRolePage',
    method: 'POST',
    data,
  })
}
export function addRole(data) {
  return request({
    url: '/user-web-api/supplier/role/addRole',
    method: 'POST',
    data,
  })
}
export function updateRole(data) {
  return request({
    url: '/user-web-api/supplier/role/updateRole',
    method: 'POST',
    data,
  })
}
export function updateBatchIsDisable(data) {
  return request({
    url: '/user-web-api/supplier/role/updateBatchIsDisable',
    method: 'POST',
    data,
  })
}
export function delRole(data) {
  return request({
    url: '/user-web-api/supplier/role/delRole',
    method: 'POST',
    data,
  })
}
export function findListByRoleIds(data) {
  return request({
    url: '/user-web-api/supplier/permission/findListByRoleIds',
    method: 'POST',
    data,
  })
}
export function getRoleNodeTreeByRoleId(data) {
  return request({
    url: '/user-web-api/supplier/role/getRoleNodeTreeByRoleId',
    method: 'POST',
    data,
  })
}
export function setRoleNode(data) {
  return request({
    url: '/user-web-api/supplier/role/setRoleNode',
    method: 'POST',
    data,
  })
}
export function pageList(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/pageList',
    method: 'POST',
    data,
  })
}
export function userAdd(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/add',
    method: 'POST',
    data,
  })
}
export function getRoleList() {
  return request({
    url: '/user-web-api/supplier/role/getRoleList',
    method: 'get',
  })
}
export function getPostList() {
  return request({
    url: '/user-web-api/supplier/post/getPostList',
    method: 'get',
  })
}

export function updateDisableIds(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/updateDisableIds',
    method: 'post',
    data,
  })
}
export function resetPwdByIds(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/resetPwdByIds',
    method: 'post',
    data,
  })
}
export function updateResignByIds(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/updateResignByIds',
    method: 'post',
    data,
  })
}
export function updateById(data) {
  return request({
    url: '/supplier-web-api/supplier/store/user/updateById',
    method: 'post',
    data,
  })
}
export function selectByToken(data) {
  return request({
    url: '/supplier-web-api/supplier/store/selectByToken',
    method: 'post',
    data,
  })
}
// 根据key查看子字典列表
export function getDictionaryListByKey(data) {
  return request({
    url: 'misc-web-api/common/dictionary/queryChildsById',
    method: 'POST',
    data,
  })
}
// 验证手机号是否已经绑定其它员工信息 true-未绑定 / false-已绑定
export function checkExistUser(data) {
  return request({
    url: 'supplier-web-api/admin/store/user/audit/checkExistUser',
    method: 'POST',
    data,
  })
}
// 更新门店关系与角色权限
export function updateResidentByIds(data) {
  return request({
    url: 'supplier-web-api/supplier/store/user/updateResidentByIds',
    method: 'POST',
    data,
  })
}

<template>
  <div class="wrap">
    <div v-if="!visible">
      <page-header @search="search" />
      <div class="table">
        <div class="btn">
          <span>
            <el-button icon="el-icon-plus" type="primary" size="small" @click="add">添加</el-button>
            <el-button size="small" @click="isEnable('批量', 0)">停用</el-button>
            <el-button size="small" @click="isEnable('批量', 1)">启用</el-button>
          </span>
        </div>
        <Table
          ref="table"
          :table-data="tableData"
          :total="total"
          :loading="loading"
          @refresh="search"
          @permissionsSet="permissionsSet"
          @edit="edit"
          @isEnable="isEnable"
          @handleSelectionChange="handleSelectionChange"
        />
        <Pages
          :current-page="form.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
    <permissions v-else :row="row" @refresh="getTableData" @cancel="visible = false" />
    <Add :row="row" :type="type" :dialog-visible.sync="dialogVisible" @refresh="getTableData" />
  </div>
</template>

<script>
import pageHeader from './header'
import Table from './table'
import Add from './components/add'
import permissions from './components/permissions'
import { getRolePage, updateBatchIsDisable } from '@/api/user'

export default {
  components: {
    pageHeader,
    Table,
    permissions,
    Add,
  },
  data() {
    return {
      visible: false,
      dialogVisible: false,
      tableData: [],
      total: 0,
      multipleSelection: [],
      showDetail: false,
      form: {
        pageSize: 10,
        pageNumber: 1,
        data: {},
      },
      loading: false,
      row: {},
      type: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {
    showDetail: function (val) {
      const a = document.querySelector('.el-tabs__header')
      if (val) {
        a.style.display = 'none'
      } else {
        a.style.display = 'block'
      }
    },
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.loading = true
      this.form.data.type = 1
      getRolePage(this.form).then((res) => {
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    search(data) {
      if (data !== 'del') {
        this.form.data = data
      }
      this.form.pageNumber = 1
      this.getTableData()
    },
    detail(row) {
      this.showDetail = true
      this.row = { ...row }
    },
    isEnable(row, e) {
      let data = {}
      let n = ''
      if (row === '批量') {
        if (this.multipleSelection.length === 0) {
          this.$message('请至少选择一条数据')
          return
        }
        data = {
          roleIds: this.multipleSelection.map((e) => e.roleId),
          upadateFlag: e === 1 ? 0 : 1,
        }
        n = e
      } else {
        data = {
          roleIds: [row.roleId],
          upadateFlag: row.isDisable === 1 ? 0 : 1,
        }
        n = row.isDisable
      }
      const tip = n === 1 ? '是否确定启用？' : '停用后，该角色的权限将失效。是否确定停用？'
      this.$confirm(tip, n === 1 ? '启用' : '停用' + '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          updateBatchIsDisable({
            data,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.getTableData()
          })
        })
        .catch(() => {})
    },
    permissionsSet(row) {
      this.row = { ...row }
      this.visible = true
    },
    add() {
      this.type = 'add'
      this.row = {}
      this.dialogVisible = true
    },
    edit(row) {
      this.type = 'edit'
      this.row = { ...row }
      this.dialogVisible = true
    },

    toggleSelection(rows) {
      this.$refs.table.toggleSelection(rows)
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
  },
}
</script>
<style lang="less" scoped>
.moreAction {
  font-size: 14px;
  color: #666;
}
</style>

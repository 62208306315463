<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="55" :selectable="selected"></el-table-column>
    <el-table-column prop="roleId" label="角色ID" min-width="100"></el-table-column>
    <el-table-column prop="roleName" label="角色姓名" min-width="180"></el-table-column>
    <el-table-column prop="description" label="角色描述" min-width="180"></el-table-column>
    <el-table-column prop="updateTime" label="最后修改时间" min-width="180"></el-table-column>
    <el-table-column prop="isDisable" label="启用状态" min-width="180">
      <template slot-scope="scope">
        <span>
          {{ scope.row.isDisable == 1 ? '停用' : '启用' }}
        </span>
      </template>
    </el-table-column>
    <el-table-column label="操作" min-width="180">
      <template slot-scope="scope">
        <span v-if="scope.row.adminType != 2">
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="permissionsSet(scope.row)">角色权限</el-button>
          <el-button type="text" size="small" @click="isEnable(scope.row)">{{ scope.row.isDisable == 1 ? '启用' : '停用' }}</el-button>
          <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { delRole } from '@/api/user'
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    selected(row, index) {
      if (row.adminType === 2) {
        return false // 不可勾选
      } else {
        return true // 可勾选
      }
    },
    edit(row) {
      this.$emit('edit', row)
    },
    isEnable(row) {
      this.$emit('isEnable', row)
    },
    del(row) {
      this.$confirm('是否确认删除该角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delRole({
            data: {
              roleId: row.roleId,
            },
          }).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.$emit('refresh', 'del')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    permissionsSet(row) {
      this.$emit('permissionsSet', row)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>

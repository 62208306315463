<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item label="角色名称：">
        <el-input
          v-model="form.roleName"
          style="width: 150px"
          placeholder="请输入关键字"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="form.isDisable" style="width: 150px" placeholder="请选择启用状态">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" value="0"></el-option>
          <el-option label="停用" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button icon="el-icon-refresh-left" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  name: 'PageHeader',
  components: {},
  data() {
    return {
      form: {},
      fold: false,
      customerCate: [],
    }
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$emit('search', this.form)
    },
    reset() {
      this.form = {}
      this.$emit('search', {})
    },

    checkFold() {
      this.fold = !this.fold
    },
  },
}
</script>
<style lang="less" scoped></style>

<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="(type == 'add' ? '新增' : '编辑') + '角色'"
    :visible.sync="dialogVisible"
    width="600px"
    :before-close="handleClose"
  >
    <div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称：" prop="roleName">
          <el-input
            v-model="ruleForm.roleName"
            maxlength="20"
            style="width: 300px"
            placeholder="请输入角色名称"
            @input="filterInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色描述：">
          <el-input
            v-model="ruleForm.description"
            maxlength="300"
            show-word-limit
            :autosize="{ minRows: 6, maxRows: 10 }"
            type="textarea"
            placeholder="请输入描述信息"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button :loading="btnLoading" type="primary" @click="ok">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addRole, updateRole } from '@/api/user'

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ruleForm: {
        roleName: '',
      },
      rules: {
        roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
      },
      btnLoading: false,
    }
  },
  watch: {
    dialogVisible(val) {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
        this.ruleForm = { roleName: '' }
        if (val && this.type === 'edit') {
          this.ruleForm = { ...this.row }
        }
      })
    },
  },
  mounted() {},
  methods: {
    filterInput(vals) {
      const forbiddenString = '超级管理员'
      if (vals === forbiddenString) {
        this.$message({
          message: '角色名称不能为超级管理员',
          type: 'error',
        })
        this.ruleForm.roleName = ''
        return false
      }
    },
    handleSelectionChange() {
      this.$emit('update:multipleSelection')
    },
    handleClose() {
      this.$refs.ruleForm.resetFields()
      this.$emit('update:dialogVisible', false)
    },
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          if (this.type === 'edit') {
            updateRole({
              data: this.ruleForm,
            })
              .then(() => {
                this.btnLoading = false
                this.$message({
                  message: '操作成功',
                  type: 'success',
                })
                this.$refs.ruleForm.resetFields()
                this.$emit('update:dialogVisible', false)
                this.$emit('refresh')
              })
              .catch(() => {
                this.btnLoading = false
              })
          } else {
            addRole({
              data: this.ruleForm,
            })
              .then(() => {
                this.btnLoading = false
                this.$message({
                  message: '操作成功',
                  type: 'success',
                })
                this.$refs.ruleForm.resetFields()
                this.$emit('update:dialogVisible', false)
                this.$emit('refresh')
              })
              .catch(() => {
                this.btnLoading = false
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-right: 10px !important;
}
</style>
